import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Importar o estilo do toast
import axios from 'axios';
import '../styles/NumerosCampanha.css'; // Importar o arquivo CSS
import config from '../config';
import imagemTelefoneC from '../assets/imagemTelefoneC.png';

const apiUrl = config.apiUrl;

const NumerosCampanha = () => {
  const [nome, setNome] = useState('');
  const [numero, setNumero] = useState('');
  const [numerosCampanha, setNumerosCampanha] = useState([]);
  const [editingNumero, setEditingNumero] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchNumerosCampanha();
  }, []);

  const fetchNumerosCampanha = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Token não encontrado. Por favor, faça login.');
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/cadastro-numeros`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setNumerosCampanha(response.data);
    } catch (error) {
      console.error('Erro ao buscar números:', error);
      toast.error('Erro ao buscar números.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Token não encontrado. Por favor, faça login.');
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/cadastro-numeros`,
        { nome, numero },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setNumerosCampanha([...numerosCampanha, response.data]);
      setNome('');
      setNumero('');
      toast.success('Número adicionado com sucesso!');
    } catch (error) {
      console.error('Erro ao adicionar número:', error);
      toast.error('Erro ao adicionar número.');
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${apiUrl}/cadastro-numeros/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setNumerosCampanha(numerosCampanha.filter((item) => item.id !== id));
      toast.success('Número excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir número:', error);
      toast.error('Erro ao excluir número. Este contato está sendo utilizado em algum disparo.');
    }
  };

  const handleEdit = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${apiUrl}/cadastro-numeros/${id}`,
        { nome, numero },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setNumerosCampanha(numerosCampanha.map((item) => (item.id === id ? response.data : item)));
      setEditingNumero(null);
      setNome('');
      setNumero('');
      toast.success('Número editado com sucesso!');
    } catch (error) {
      console.error('Erro ao editar número:', error);
      toast.error('Erro ao editar número.');
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!file) {
      toast.error('Nenhum arquivo selecionado.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(`${apiUrl}/cadastro-numeros/upload-excel`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Arquivo Excel enviado com sucesso!');
      fetchNumerosCampanha(); // Recarrega os números após o upload
    } catch (error) {
      console.error('Erro ao enviar arquivo:', error);
      toast.error('Erro ao enviar arquivo.');
    }
  };

  return (
    <div className="numeros-campanha-container">
      <h1>Cadastro de Números de Campanha</h1>
      <img src={imagemTelefoneC} alt="CadastroNumbImage" className="cnumb-image" />
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label>Nome:</label>
          <input
            type="text"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            placeholder="Nome do cliente"
            required
          />
        </div>
        <div className="form-group">
          <label>Número:</label>
          <input
            type="text"
            value={numero}
            onChange={(e) => setNumero(e.target.value.replace(/\D/g, ''))}
            placeholder="Ex: 5511999999999"
            required
          />
        </div>
        <button type="submit" className="submit-button">
          {editingNumero ? 'Salvar Edição' : 'Adicionar Número'}
        </button>
      </form>

      <h2>Enviar Números por Excel</h2>
      
      <form onSubmit={handleFileUpload} className="file-upload-form">
        <input type="file" accept=".xlsx, .xls" onChange={(e) => setFile(e.target.files[0])} />
        <a href="padrao_numeros.xlsx" download>
          <button type="button" className="download-xls-button">Download do modelo Excel</button>
        </a>
        <button type="submit" className="upload-button">Enviar Arquivo</button>
      </form>

      <h2>Lista de Números de Campanha</h2>
      {numerosCampanha.length === 0 ? (
        <p>Nenhum número cadastrado</p>
      ) : (
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Número</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {numerosCampanha.map((item) => (
                <tr key={item.id}>
                  <td>{item.nome}</td>
                  <td>{item.numero}</td>
                  <td>
                    <button
                      className="edit-button"
                      onClick={() => {
                        setEditingNumero(item.id);
                        setNome(item.nome);
                        setNumero(item.numero);
                      }}
                    >
                      Editar
                    </button>
                    <button className="delete-button" onClick={() => handleDelete(item.id)}>
                      Excluir
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {editingNumero && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>Editar Número</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleEdit(editingNumero);
              }}
            >
              <div className="form-group">
                <label>Nome:</label>
                <input
                  type="text"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Número:</label>
                <input
                  type="text"
                  value={numero}
                  onChange={(e) => setNumero(e.target.value.replace(/\D/g, ''))}
                  required
                />
              </div>
              <button type="submit" className="save-button">Salvar</button>
              <button
                type="button"
                className="cancel-button"
                onClick={() => setEditingNumero(null)}
              >
                Cancelar
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default NumerosCampanha;
