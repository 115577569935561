import React, { useState, useEffect } from 'react';
import HeaderUser from './HeaderUser';
import ServicosTable from './ServicosTable';
import Atendentes from './Atendentes';
import UpdateUserInfo from './UpdateUserInfo';
import ManageMensagens from './ManageMensagens';
import ManageParametros from './ManageParametros';
import HistoricoMensagens from './HistoricoMensagens';
import ServicoIndisponivel from './ServicoIndisponivel';
import axios from 'axios';
import config from '../config';
import Campanha from './Campanha';
import Disparo from './Disparo';
import NivelAutenticacao from './NivelAutenticacao';
import FeedBackDashboard from './FeedBackDashboard';
import NumerosCampanha from './NumerosCampanha';

const apiUrl = config.apiUrl;

const UserDashboard = ({ userName, onLogout }) => {
  const [activeComponent, setActiveComponent] = useState('servicos');
  const [isAtendenteServiceAvailable, setIsAtendenteServiceAvailable] = useState(true);
  const [isCampanhaServiceAvailable, setIsCampanhaServiceAvailable] = useState(true);
  const [isDisparoServiceAvailable, setIsDisparoServiceAvailable] = useState(true);
  const [isNivelAutenticacaoServiceAvailable, setIsNivelAutenticacaoServiceAvailable] = useState(true);
  const [isFeedbackServiceAvailable, setIsFeedbackServiceAvailable] = useState(true);

  useEffect(() => {
    const checkServiceStatus = async (serviceId, setStateFunction) => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${apiUrl}/servicos/verificarstatus/${serviceId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setStateFunction(response.data);
      } catch (error) {
        console.error(`Erro ao verificar status do serviço com id ${serviceId}:`, error);
        setStateFunction(false);
        window.location.reload();
      }
    };

    checkServiceStatus(5, setIsAtendenteServiceAvailable);
    checkServiceStatus(9, setIsCampanhaServiceAvailable);
    checkServiceStatus(9, setIsDisparoServiceAvailable);
    checkServiceStatus(8, setIsNivelAutenticacaoServiceAvailable);
    checkServiceStatus(10, setIsFeedbackServiceAvailable);
  }, []);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'servicos':
        return <ServicosTable />;
      case 'parametros':
        return <ManageMensagens />;
      case 'atendentes':
        return isAtendenteServiceAvailable ? <Atendentes /> : <ServicoIndisponivel />;
      case 'historico':
        return <HistoricoMensagens />;
      case 'conta':
        return <UpdateUserInfo />;
      case 'parametros2':
        return <ManageParametros />;
      case 'campanhas':
        return isCampanhaServiceAvailable ? <Campanha /> : <ServicoIndisponivel />;
      case 'disparos':
        return isDisparoServiceAvailable ? <Disparo /> : <ServicoIndisponivel />;
      case 'cadastroTelefones':
        return isDisparoServiceAvailable ? <NumerosCampanha /> : <ServicoIndisponivel />;
      case 'autenticacao':
        return isNivelAutenticacaoServiceAvailable ? <NivelAutenticacao /> : <ServicoIndisponivel />;
      case 'feedback':
        return  isFeedbackServiceAvailable ? <FeedBackDashboard/> : <ServicoIndisponivel />;
      default:
        return <h1>This is an error</h1>;
    }
  };

  return (
    <div>
      <HeaderUser onNavItemClick={setActiveComponent} userName={userName} onLogout={onLogout} />
      <div className="content">
        {renderComponent()}
      </div>
    </div>
  );
};

export default UserDashboard;
