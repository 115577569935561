import React from 'react';

const MessageContainer = ({ title, mensagens, className, nomeRobo, atendenteNumero }) => {
  return (
    <div className={`message-container ${className}`}>
      <h3>{title}</h3>
      <div className="message-list">
        {mensagens.length > 0 ? (
          mensagens.map((msg, index) => {
            let remetente = msg.remetente;
            let mensagem = msg.mensagem;

            // Verifica se a mensagem começa com nomeRobo + ':'
            const roboPrefix = `${nomeRobo}:`;
            if (mensagem.startsWith(roboPrefix)) {
              remetente = nomeRobo; // Substitui o remetente por nomeRobo
              mensagem = mensagem.replace(roboPrefix, '').trim(); // Remove o prefixo e ':' da mensagem
            }

            let messageClass = 'user';
            if (remetente === atendenteNumero) {
              messageClass = 'atendente';
            } else if (remetente === 'bot') {
              messageClass = 'bot';
            }

            return (
              <div key={index} className={`message ${messageClass}`}>
                <p><strong>{remetente === 'bot' ? nomeRobo : remetente === atendenteNumero ? 'Atendente' : remetente}:</strong> {mensagem}</p>
                <span>{new Date(msg.data).toLocaleString()}</span>
              </div>
            );
          })
        ) : (
          <p>Nenhuma mensagem encontrada.</p>
        )}
      </div>
    </div>
  );
};

export default MessageContainer;
