import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import '../styles/AtendimentosAnalytics.css';
import config from '../config';
const apiUrl = config.apiUrl;

const AtendimentosAnalytics = ({ atendenteId }) => {
  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [dailyData, setDailyData] = useState([]);
  const [feedbackPieData, setFeedbackPieData] = useState([]);
  const [percentualProtocolados, setPercentualProtocolados] = useState(0);
  const [tempoMedioResposta, setTempoMedioResposta] = useState(0);
  const [status, setStatus] = useState('true');
  const [atendimentosAbertos, setAtendimentosAbertos] = useState([]);

  useEffect(() => {
    fetchPeriods();
  }, [atendenteId]);

  useEffect(() => {
    if (selectedPeriod) {
      const [mes, ano] = selectedPeriod.split('/');
      fetchDailyAtendimentos(mes, ano, atendenteId);
      fetchFeedbackPieData(mes, ano, atendenteId);
      fetchProtocoladosPercentual(atendenteId, mes, ano);
      fetchTempoMedioResposta(atendenteId, mes, ano);
      fetchAtendimentosAbertos(mes, ano, atendenteId, status === 'true');
    }
  }, [selectedPeriod, atendenteId, status]);
  const handleChangeAtndAtivos = (e) => {
    const aberto = e.target.value === 'true';
    setStatus(e.target.value); // Atualiza o valor do select
    fetchAtendimentosAbertos(selectedPeriod.split('/')[0], selectedPeriod.split('/')[1], atendenteId, aberto);
  };

  const fetchPeriods = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/atendente/periodos`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { atendenteId },
      });
      setPeriods(response.data);
      setSelectedPeriod(response.data[0]);
    } catch (error) {
      console.error('Erro ao buscar períodos', error);
    }
  };
  const fetchAtendimentosAbertos = async (mes, ano, atendenteId, aberto) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/atendente/atendimentos-abertos`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { mes, ano, atendenteId, aberto },
      });
      setAtendimentosAbertos(response.data);
      
    } catch (error) {
      console.error('Erro ao buscar atendimentos abertos', error);
    }
  };

  const fetchDailyAtendimentos = async (mes, ano, atendenteId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/atendente/atendimentos/diarios`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { mes, ano, atendenteId },
      });
      setDailyData(response.data);
    } catch (error) {
      console.error('Erro ao buscar atendimentos diários', error);
    }
  };

  const fetchFeedbackPieData = async (mes, ano, atendenteId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/feedback/pie-atendentes`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { mes, ano, atendenteId },
      });
      setFeedbackPieData(response.data);
    } catch (error) {
      console.error('Erro ao buscar feedbacks para gráfico de pizza', error);
    }
  };

  const fetchProtocoladosPercentual = async (atendenteId, mes, ano) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/atendente/atendimentos/protocolados/percentual`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { atendenteId, mes, ano },
      });
      setPercentualProtocolados(response.data);
    } catch (error) {
      console.error('Erro ao buscar percentual de atendimentos protocolados', error);
    }
  };

  const fetchTempoMedioResposta = async (atendenteId, mes, ano) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/historico/tempo-medio-resposta`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { atendenteId, mes, ano },
      });
      setTempoMedioResposta(response.data);
    } catch (error) {
      console.error('Erro ao buscar tempo médio de resposta', error);
    }
  };

  const handlePeriodChange = (event) => {
    const [mes, ano] = event.target.value.split('/');
    setSelectedPeriod(event.target.value);
    fetchDailyAtendimentos(mes, ano, atendenteId);
    fetchFeedbackPieData(mes, ano, atendenteId);
    fetchProtocoladosPercentual(atendenteId, mes, ano);
    fetchTempoMedioResposta(atendenteId, mes, ano);
    fetchAtendimentosAbertos(mes, ano, atendenteId, status === 'true');
  };

  const lineChartOptions = {
    chart: {
      type: 'line', toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: dailyData.map((item) => item.dia),
    },
    title: {
      text: 'Atendimentos Diários',
    },
  };

  const pieChartOptions = {
    chart: { type: 'pie' },
    labels: feedbackPieData.map((item) => item.nivelsatisfacao),
    title: {
      text: 'Feedbacks',
    },
  };

  return (
    <div className='atndAnalytics-pai'> 
    <div className="analytics-container">
      <div className="period-selection">
        <label htmlFor="period">Selecione o Período:</label>
        <select id="period" value={selectedPeriod} onChange={handlePeriodChange}>
          {periods.map((period, index) => (
            <option key={index} value={period}>
              {period}
            </option>
          ))}
        </select>
      </div>
      <div className="charts-row">
        <div className="line-chart">
          <ApexCharts
            options={lineChartOptions}
            series={[
              {
                name: 'Atendimentos',
                data: dailyData.map((item) => item.quantidade),
              },
            ]}
            type="line"
            height={350}
          />
        </div>

        <div className="pie-chart">
          <ApexCharts
            options={pieChartOptions}
            series={feedbackPieData.map((item) => item.quantidade)}
            type="pie"
            height={350}
          />
        </div>
      </div>

      <div className="info-boxes-row">
        <div className="info-box">
          <h3>Percentual de Atendimentos Protocolados</h3>
          <p>{percentualProtocolados}%</p>
        </div>

        <div className="info-box">
          <h3>Tempo Médio de Resposta</h3>
          <p>{tempoMedioResposta} minuto(s)</p>
        </div>
      </div>
    </div>
    <div className="status-selection">
        <label htmlFor="status">Atendimento em protocolo:</label>
        <select id="status" value={status} onChange={handleChangeAtndAtivos}>
          <option value="true">Sim</option>
          <option value="false">Não</option>
        </select>
      </div>

      <table className="atendimentos-table">
        <thead>
          <tr>
            <th>Protocolo</th>
            <th>Data de Abertura</th>
            <th>Cliente</th>
          </tr>
        </thead>
        <tbody>
          {atendimentosAbertos.map((atendimento) => (
            <tr key={atendimento.protocolo}>
              <td>{atendimento.protocolo}</td>
              <td>{new Date(atendimento.data).toLocaleString()}</td>
              <td>{atendimento.clienteNumero}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AtendimentosAnalytics;
