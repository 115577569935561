import React, { useState, useEffect } from 'react';
import '../styles/Atendentes.css';
import config from '../config';
import atendimentoImage from '../assets/atendimentoImage.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AtendimentosAnalytics from './AtendimentosAnalytics';
import InputMask from 'react-input-mask';
const apiUrl = config.apiUrl;

const Atendentes = () => {
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [setor, setSetor] = useState('SAC');
  const [atendimentosSimultaneos, setAtendimentosSimultaneos] = useState(1); // Novo estado
  const [atendentes, setAtendentes] = useState([]);
  const [editingAtendente, setEditingAtendente] = useState(null);
  const [visualizacaoUnica, setVisualizacaoUnica] = useState(false);
  const [preAtendimento, setPreAtendimento] = useState(false);
  const [selectedAtendenteId, setSelectedAtendenteId] = useState(null);
  const [horarioEntrada, setHorarioEntrada] = useState('');
  const [horarioSaida, setHorarioSaida] = useState('');
  const [quantidade, setQuantidade] = useState(0);
  const[isSelectEnabled, setIsSelectEnabled] = useState(false);
  const quantidadeList = Array.from({ length: 21 }, (_, i) => i); 
  const handleAtendenteChange = (e) => {
    setSelectedAtendenteId(e.target.value);
  };



  useEffect(() => {
    fetchAtendentes();
    fetchVisualizacaoUnica();
    fetchPreAtendimento();
  }, []);
  const handleQuantidadeChange = async (e) => {
    const novaQuantidade = parseInt(e.target.value, 10); // Convertendo valor para inteiro
    

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }
    
    try {
      const response = await fetch(`${apiUrl}/atendente/pre-atendimento-toggle`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quantidade: novaQuantidade, // Usando a variável quantidade
        }),
      });
      setQuantidade(novaQuantidade); // Atualizando estado
      const updatedStatus = await response.json();
      setPreAtendimento(updatedStatus);
      setIsSelectEnabled(updatedStatus);
      console.log("atualizou para quantidade", novaQuantidade)
      if (updatedStatus) {
        toast.success('Nova quantidade mensagens adicionada!');
      } else {
        toast.success('Encerrando pre-atendimento. Todas mensagens foram deletadas!');
      }
    } catch (error) {
      console.error('Erro ao enviar requisição:', error);
    }
  };
  const fetchPreAtendimento = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/atendente/pre-atendimento`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPreAtendimento(data.ativo);
        setQuantidade(data.quantidade);
        setIsSelectEnabled(data.ativo);

      } else {
        console.error('Failed to fetch pre-atendimento');
      }
    } catch (error) {
      console.error('Error fetching pre-atendimento:', error);

    }
  };

  const togglePreAtendimento = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    try {
      const novaQuantidade = preAtendimento ? 0 : 1;
      const response = await fetch(`${apiUrl}/atendente/pre-atendimento-toggle`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quantidade: novaQuantidade
        }),
      });




      if (response.ok) {
        const updatedStatus = await response.json();
        setPreAtendimento(novaQuantidade);
        setIsSelectEnabled(novaQuantidade);
        setQuantidade(novaQuantidade);
        if (novaQuantidade) {
          toast.success('Pré atendimento ativado com sucesso!');
        } else {
          toast.success('Pré atendimento desativado com sucesso!');
        }
      } else {
        console.error('Failed to update pre-atendimento');
      }
    } catch (error) {
      console.error('Error updating pre-atendimento:', error);
      toast.error('Erro ao atualizar pré atendimento, tente reiniciar o navegador.')
    }
  };
  const fetchVisualizacaoUnica = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/atendente/visualizacao-unica`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const isAtivo = await response.json();
      setVisualizacaoUnica(isAtivo);
    } catch (error) {
      console.error('Error fetching visualizacao unica:', error);
    }
  };
  const toggleVisualizacaoUnica = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/atendente/visualizacao-unica`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setVisualizacaoUnica(!visualizacaoUnica);
        toast.success('Visualização única atualizada com sucesso!');
      } else {
        console.error('Failed to toggle visualizacao unica');
      }
    } catch (error) {
      console.error('Error toggling visualizacao unica:', error);
      toast.error('Erro ao atualizar visualização única.');
    }
  };
  const fetchAtendentes = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/atendente`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setAtendentes(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      window.location.reload();
    }
  };

  const formatTelefone = (telefone) => {
    const numericValue = telefone.replace(/\D/g, '');
    let formattedTelefone = '+' + numericValue.slice(0, 2) + ' ' + numericValue.slice(2, 4) + ' ';

    if (numericValue.length === 13) {
      const primeiraParte = numericValue.slice(4, 9);
      const segundaParte = numericValue.slice(9);
      formattedTelefone += primeiraParte + '-' + segundaParte;
    } else if (numericValue.length === 12) {
      const primeiraParte = numericValue.slice(4, 8);
      const segundaParte = numericValue.slice(8);
      formattedTelefone += primeiraParte + '-' + segundaParte;
    } else {
      return telefone;
    }

    return formattedTelefone;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    const formattedTelefone = formatTelefone(telefone);

    if (formattedTelefone === telefone) {
      toast.error('Número de telefone inválido. Por favor, insira um número correto somente com algarismos.');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/atendente`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome, telefone: formattedTelefone, setor, atendimentosSimultaneos, horarioEntrada, // Inclui o horário de entrada na requisição
          horarioSaida
        }),
      });

      if (response.ok) {
        const newAtendente = await response.json();
        setAtendentes([...atendentes, newAtendente]);
        setNome('');
        setTelefone('');
        setSetor('SAC');
        setAtendimentosSimultaneos(1); // Resetar o campo
        toast.success('Atendente adicionado com sucesso!');
      } else {
        console.error('Failed to add atendente');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Erro ao adicionar atendente.');
      window.location.reload();
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiUrl}/atendente/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 204) {
        setAtendentes(atendentes.filter((atendente) => atendente.id !== id));
        toast.success('Atendente excluído com sucesso!');
      } else {
        console.error('Failed to delete atendente');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Erro ao excluir atendente.');
      window.location.reload();
    }
  };

  const handleEditClick = (atendente) => {
    setEditingAtendente({ ...atendente, telefone: formatTelefone(atendente.telefone) });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    const formattedTelefone = formatTelefone(editingAtendente.telefone);

    try {
      const response = await fetch(`${apiUrl}/atendente/${editingAtendente.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...editingAtendente, telefone: formattedTelefone }),
      });

      if (response.ok) {
        const updatedAtendente = await response.json();
        setAtendentes(atendentes.map((atendente) =>
          atendente.id === updatedAtendente.id ? updatedAtendente : atendente
        ));
        setEditingAtendente(null);
        toast.success('Atendente atualizado com sucesso!');
      } else {
        console.error('Failed to update atendente');
        toast.error('Erro ao atualizar atendente.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Erro ao atualizar atendente.');
      window.location.reload();
    }
  };

  const handleTelefoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setTelefone(value);
  };

  return (
    <div className='container-atendentes'>
      <div className="container">
        <h1>Cadastro de Atendentes</h1>
        <img src={atendimentoImage} alt="Atendimento" className="atendimento-image" />
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label>Nome:</label>
            <input
              type="text"
              value={nome}
              placeholder='Ex: Luís'
              onChange={(e) => setNome(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Telefone:</label>
            <input
              type="text"
              value={telefone}
              placeholder='Ex: 5528999272312'
              onChange={handleTelefoneChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Setor:</label>
            <select value={setor} onChange={(e) => setSetor(e.target.value)}>
              <option value="SAC">SAC</option>
              <option value="FINANCEIRO">FINANCEIRO</option>
              <option value="COMERCIAL">COMERCIAL</option>
            </select>
          </div>
          <div className="form-group">
            <label>Atendimentos Simultâneos:</label>
            <input
              type="number"
              value={atendimentosSimultaneos}
              onChange={(e) => setAtendimentosSimultaneos(parseInt(e.target.value) || 1)}
              required
              min="1"
            />
          </div>
          <div className="form-group">
            <label>Horário de Entrada:</label>
            <InputMask
              mask="99:99"
              value={horarioEntrada}
              onChange={(e) => setHorarioEntrada(e.target.value)}
              placeholder="Ex: 09:00"
              required
            />
          </div>

          <div className="form-group">
            <label>Horário de Saída:</label>
            <InputMask
              mask="99:99"
              value={horarioSaida}
              onChange={(e) => setHorarioSaida(e.target.value)}
              placeholder="Ex: 18:00"
              required
            />
          </div>
          <button type="submit" className="submit-button">Adicionar Atendente</button>
        </form>

        <h2>Lista de Atendentes</h2>
        {atendentes.length === 0 ? (
          <p>Nenhum atendente cadastrado</p>
        ) : (
          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Telefone</th>
                  <th>Setor</th>
                  <th>Atendimentos Simultâneos</th> {/* Nova coluna */}
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {atendentes.map((atendente) => (
                  <tr key={atendente.id}>
                    <td>{atendente.nome}</td>
                    <td>{atendente.telefone}</td>
                    <td>{atendente.setor}</td>
                    <td>{atendente.atendimentosSimultaneos}</td> {/* Mostrar o valor de atendimentosSimultaneos */}
                    <td>
                      <button
                        onClick={() => handleEditClick(atendente)}
                        className="edit-button"
                      >
                        Editar
                      </button>
                      <button
                        onClick={() => handleDelete(atendente.id)}
                        className="delete-button"
                      >
                        Excluir
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {editingAtendente && (
          <div className="edit-popup">
            <div className="edit-popup-content">
              <h2>Editar Atendente</h2>
              <form onSubmit={handleUpdate}>
                <div className="form-group">
                  <label>Nome:</label>
                  <input
                    type="text"
                    value={editingAtendente.nome}
                    onChange={(e) =>
                      setEditingAtendente({ ...editingAtendente, nome: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Telefone:</label>
                  <input
                    type="text"
                    value={editingAtendente.telefone}
                    onChange={(e) =>
                      setEditingAtendente({ ...editingAtendente, telefone: e.target.value })
                    }
                    onBlur={() =>
                      setEditingAtendente({
                        ...editingAtendente,
                        telefone: formatTelefone(editingAtendente.telefone),
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Setor:</label>
                  <select
                    value={editingAtendente.setor}
                    onChange={(e) =>
                      setEditingAtendente({ ...editingAtendente, setor: e.target.value })
                    }
                  >
                    <option value="SAC">SAC</option>
                    <option value="FINANCEIRO">FINANCEIRO</option>
                    <option value="COMERCIAL">COMERCIAL</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Horário de Entrada:</label>
                  <InputMask
                    mask="99:99"
                    value={editingAtendente.horarioEntrada}
                    onChange={(e) =>
                      setEditingAtendente({ ...editingAtendente, horarioEntrada: e.target.value })
                    }
                    placeholder="Ex: 09:00"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Horário de Saída:</label>
                  <InputMask
                    mask="99:99"
                    value={editingAtendente.horarioSaida}
                    onChange={(e) =>
                      setEditingAtendente({ ...editingAtendente, horarioSaida: e.target.value })
                    }
                    placeholder="Ex: 18:00"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Ativo:</label>
                  <input
                    type="checkbox"
                    checked={editingAtendente.ativo}
                    onChange={(e) =>
                      setEditingAtendente({ ...editingAtendente, ativo: e.target.checked })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Atendimentos Simultâneos:</label>
                  <input
                    type="number"
                    value={editingAtendente.atendimentosSimultaneos}
                    onChange={(e) =>
                      setEditingAtendente({ ...editingAtendente, atendimentosSimultaneos: parseInt(e.target.value) || 1 })
                    }
                    required
                    min="1"
                  />
                </div>
                <button type="submit" className="update-button">Atualizar</button>
                <button type="button" className="cancel-button" onClick={() => setEditingAtendente(null)}>Cancelar</button>
              </form>
            </div>
          </div>
        )}
        <div className="visualizacao-container">
          <h3>Habilitar visualização única para arquivos de mídia</h3>
          <div className="form-group">
            <input
              type="checkbox"
              checked={visualizacaoUnica}
              onChange={toggleVisualizacaoUnica}
            />

          </div>
        </div>
        <div className="pre-atendimento-container">
          <h3>Habilitar Pré-Atendimento</h3>
          <p>Crie as mensagens na aba de mensagens para ativar cada etapa de acordo com sua necessidade. Todas mensagens devem conter 1. sim (cliente já realizou essa etapa) e 2. não (não realizou a etapa), cada mensagem contêm um contrafluxo para orientar o cliente caso marque não anteriormente.</p>
          <div className="form-group">
            <input
              type="checkbox"
              checked={preAtendimento}
              onChange={togglePreAtendimento}
            />
            <select
              id="quantidade"
              value={quantidade} // Aqui o valor do select é controlado pelo estado
              onChange={handleQuantidadeChange}
              disabled={!isSelectEnabled}
            >
              {quantidadeList.map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>

          </div>
        </div>

      </div>
      <div className="analytics-atendente">
        <label>Selecione um Atendente:</label>
        <select value={selectedAtendenteId} onChange={handleAtendenteChange}>
          <option value="">Selecione um atendente</option>
          {atendentes.map((atendente) => (
            <option key={atendente.id} value={atendente.id}>
              {atendente.nome} - {atendente.telefone}
            </option>
          ))}
        </select>
      </div>
      <div className='dashboard-atendente'>
        {selectedAtendenteId && (
          <AtendimentosAnalytics atendenteId={selectedAtendenteId} />
        )}
      </div>
    </div>
  );
};

export default Atendentes;
