import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import config from '../config';

const FeedPieChart = ({ series, labels }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'pie'
      },
      labels: [],
      title: {
        text: 'Nível de Satisfação dos Feedbacks',
        align: 'center'
      }
    }
  });

  // useEffect para atualizar chartData quando as series ou labels mudarem
  useEffect(() => {
    setChartData((prevState) => ({
      ...prevState,
      series,  // Atualiza as séries
      options: {
        ...prevState.options,
        labels  // Atualiza os rótulos
      }
    }));
  }, [series, labels]);  // O useEffect vai rodar sempre que series ou labels mudarem

  return (
    <div>
      <Chart options={chartData.options} series={chartData.series} type="pie" height={350} />
    </div>
  );
};

export default FeedPieChart;
